import { createContext, useContext, useReducer } from "react";

const AppContext = createContext({});

const initialState = {
  user: null,
  isLoggedIn: false,
};

function appReducer(state, action) {
  switch (action.type) {
    case "set_user":
      return {
        ...state,
        user: action.payload ? action.payload : null,
        isLoggedIn: action.payload ? true : false,
      };
    default:
      return initialState;
  }
}

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  return { ...context };
}

export default AppContextProvider;
