import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  Badge,
  Button,
  Label,
  Radio,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";

import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { HiMail } from "react-icons/hi";
import { IoCallOutline } from "react-icons/io5";
import { MdCheck, MdLocalPhone, MdOutlineLocalHospital } from "react-icons/md";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { featureIcons } from "../../_constants";
import { useAlert } from "../../_contexts/AlertContext";
import { property as p } from "../../_data";
import EMICalculator from "../../_helpers/EMICalculator";
import commaSeparatedNum from "../../_helpers/commaSeparatedNum";
import { enquirySchema } from "../../_helpers/formSchema";
import htmlToText from "../../_helpers/htmlToText";
import normalizeCamelCase from "../../_helpers/normalizeCamelCase";
import { getProperty, getTeam, postCustomer, postOffer } from "../../apis";
import Gallery from "../../components/Gallery";
import TikTokEmbed from "../../components/TiktokEmbed";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import Footer from "../../layouts/DefaultLayout/Footer";
import Header from "../../layouts/DefaultLayout/Header";
import Featured from "../Home/Featured";
import Popular from "../Home/Popular";
import SimilarProperties from "../Home/SimilarProperty";
import Tool from "../Tools";
export default function Property() {
  const mainRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { pathname } = useLocation();
  const adminActive = pathname.split("/")[1] === "admin" ? true : false;
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["property", id],
    queryFn: () => getProperty(id),
  });
  const { data: enquiry } = useQuery({
    queryKey: ["team"],
    queryFn: () => getTeam(data?.data?.enquiryTo),
  });
  const { setAlert } = useAlert();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defautValues: {},
    resolver: yupResolver(enquirySchema),
  });
  const property = isLoading ? p : data?.data;
  const handleCarouselChange = (newIndex) => {
    setCurrentIndex(newIndex);
  };
  const handleSubmitForm = async (data) => {
    const { phone, email, name, type, offer } = data;
    const enquiryResult = {
      phone,
      email,
      name,
      type,
      attachedProperty: property?._id,
    };
    const offerResult = { phone, name, offer, email, property: property?._id };
    if (data?.offer === null || data?.offer === undefined) {
      await postCustomer(enquiryResult);
    } else {
      await postOffer(offerResult);
    }

    reset();
  };

  return !isLoading ? (
    <>
      <Helmet>
        <title>{property?.name}</title>
        <meta name="description" content={htmlToText(property?.description)} />
        <meta
          name="keywords"
          content={property?.otherDetails?.map((d) => d.name).join(", ")}
        />
        <meta property="og:title" content={property?.name} />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:url"
          content={`https://gprealestate.com.np/contact/${property?.propertyId}`}
        />
        <meta property="og:image" content={property?.images?.[0]?.src} />
        <meta
          property="og:description"
          content={htmlToText(property?.description)}
        />
      </Helmet>
      {!adminActive && <Header contentRef={mainRef} />}
      <section
        className={[
          "w-full ",
          showModal ? "max-h-screen overflow-hidden" : "",
        ].join(" ")}
      >
        <section
          className={`${
            adminActive ? "" : "md:py-20 py-10  md:px-auto md:w-4/5 mx-auto"
          }px-4 mx-auto`}
        >
          {/* Title */}
          <section className="flex flex-col md:flex-row md:justify-between py-8 mx-2 md:mx-0">
            <div>
              {adminActive && (
                <FaArrowLeft
                  fontSize="20px"
                  onClick={() => navigate("/admin/property/list/1")}
                />
              )}
              <h1 className="text-xl md:text-3xl text-lime-600 uppercase tracking-wider">
                <span className="font-bold mr-2">{property?.name}</span>
              </h1>
              <p className="font-bold text-lg md:text-lg text-slate-500">
                {property?.location?.district}, {property?.location?.province}
              </p>
              <p
                className="text-slate-500 text-xs md:text-sm font-semibold block md:inline cursor-pointer"
                onClick={() => {
                  window.navigator.clipboard.writeText(property?.propertyId);
                  setAlert({
                    isOpen: true,
                    message: "Copied to Clipboard",
                    type: "info",
                  });
                }}
              >
                (Property Code: {property?.propertyId})
              </p>
              <p className="text-slate-500 text-sm italic">
                Posted At: {dayjs(property?.createdAt).format("dddd MMM, YYYY")}
              </p>
            </div>
            <div className="flex flex-col md:items-end space-y-4">
              <Badge
                className="w-max text-xl px-8 rounded-md shadow-lg"
                color={property?.sold ? "failure" : "info"}
              >
                {property?.sold ? "Sold" : "Sale"}
              </Badge>
            </div>
          </section>
          {/* Images */}
          <section className="mb-4 md:mb-8">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:max-w-[50vw] h-auto">
                <Gallery
                  image={property?.images}
                  handleCarouselChange={handleCarouselChange}
                  className=""
                />
                <div className="flex gap-x-2 justify-center">
                  {property?.images?.map((d, i) => (
                    <p
                      key={i}
                      className={`capitalize ${
                        currentIndex === i
                          ? "font-bold text-primary"
                          : "text-neutral-400"
                      } text-sm`}
                    >
                      {d?.view || "OutsideView"}
                    </p>
                  ))}
                </div>
              </div>
              <div>
                <h2
                  className={[
                    "text-xl md:text-2xl font-semibold text-lime-600 ",
                    property?.sold && " line-through decoration-red-500",
                  ]}
                >
                  {property?.price?.unit}{" "}
                  {commaSeparatedNum(+property?.price?.value || 0)} /-
                  <span
                    className="text-blue-500 text-base cursor-pointer block"
                    onClick={() => setShowModal(!showModal)}
                  >
                    (
                    {commaSeparatedNum(
                      EMICalculator(property?.price?.value, 8, 60, "month").emi
                    )}{" "}
                    /mo*)
                  </span>
                </h2>
                {showModal && (
                  <div className="max-h-screen absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 shadow-lg bg-white rounded-md overflow-auto">
                    <div>
                      <RxCross2
                        className="absolute top-4 right-4 cursor-pointer"
                        fontSize={"20px"}
                        onClick={() => setShowModal(!showModal)}
                      />
                    </div>
                    <Tool
                      initialState={{
                        principal: property?.price?.value,
                        interestRate: "9",
                        tenure: "24",
                      }}
                    />
                  </div>
                )}
                {showModal && (
                  <div
                    className="w-screen min-h-screen bg-slate-300/40 absolute top-0 left-0 z-30 overflow-hidden"
                    onClick={() => setShowModal(!showModal)}
                  ></div>
                )}

                <p className="text-xs text-neutral-400">
                  *monthly EMI may vary depending different rates
                </p>
                <p className="text-red-500">{property?.sold && "Sold"}</p>
                <ul className="py-4">
                  {property?.otherDetails
                    ?.filter((item) => item?.value === "true")
                    .map((d, index) => (
                      <li
                        key={index}
                        className="capitalize  text-slate-600 text-sm"
                      >
                        <MdCheck className="inline text-emerald-500" />{" "}
                        {d?.name}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-2 md:py-16 py-8">
              {Object.keys(property?.details || {}).map((key) => {
                let details = { ...property?.details };
                details.parking = [
                  featureIcons.bikes,
                  <span>{details.parking.bikes || 0}</span>,
                  featureIcons.cars,
                  <span>{details.parking.cars || 0}</span>,
                ];
                details.roadAccess = [
                  ...(details.roadAccess?.access
                    ? [
                        details?.roadAccess?.width,
                        " ",
                        details?.roadAccess?.unit,
                      ]
                    : ["No"]),
                ];
                return (
                  <PropertyDetailBox details={details} key={key} k={key} />
                );
              })}
            </div>
          </section>
          {/* Details */}
          <section className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
            <div className="col-span-3 md:col-span-2 bg-white p-4 rounded-lg">
              {["apartment", "house"].includes(property?.category) && (
                <div className="pb-8 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-0">
                  <div className="shadow-lg p-4 border border-slate-200 grow rounded">
                    <span className="mr-2 font-semibold">Built Year:</span>
                    <span>
                      {property?.builtYear?.year} {property?.builtYear?.type}
                    </span>
                  </div>
                  <div className="shadow-lg p-4 border border-slate-200 grow rounded">
                    <span className="mr-2 font-semibold">Facing:</span>
                    <span>{normalizeCamelCase(property?.facing)}</span>
                  </div>
                  <div className="shadow-lg p-4 border border-slate-200 grow rounded">
                    <span className="mr-2 font-semibold">Furnished:</span>
                    <span>{normalizeCamelCase(property?.furnishedStatus)}</span>
                  </div>
                </div>
              )}
              <p
                className={[
                  "indent-5 md:indent-10 text-justify md:text-base",
                ].join(" ")}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: property?.description }}
                ></div>
              </p>
              <div className="py-8 flex gap-2 md:gap-1">
                <div className="shadow-lg p-4 border border-slate-200 grow rounded text-center">
                  <span className="mr-2 font-semibold text-lime-600">
                    OwnerShip:
                  </span>
                  <span>{property?.ownerShip}</span>
                </div>
                {property?.premimum && (
                  <div className="shadow-lg p-4 border border-slate-200 grow rounded text-center">
                    <span className="mr-2 font-semibold text-lime-600">
                      Premium:
                    </span>
                    <span>{property?.premimum ? "Yes" : "No"}</span>
                  </div>
                )}
                <div className="shadow-lg p-4 border border-slate-200 grow rounded text-center">
                  <span className="mr-2 font-semibold text-lime-600">
                    Area:
                  </span>
                  <span>
                    {property?.area?.type === "Hilly" &&
                      `${property?.area?.aana} aana ${property?.area?.paisa} paisa ${property?.area?.ropani} ropani`}
                  </span>
                  <span>
                    {property?.area?.type === "Terai" &&
                      `${property?.area?.dhur} dhur ${property?.area?.kattha} kattha ${property?.area?.bigga} bigga`}
                  </span>
                </div>
              </div>
              {
                <div className="">
                  {property?.social?.youtube?.map(
                    (d, i) => d && <YoutubeEmbed url={d} key={i} />
                  )}
                </div>
              }
              <div className="py-8 space-y-2">
                <p className="font-bold text-lg md:text-lg">NearBy Places</p>
                <div>
                  <div className="space-y-2">
                    <span className="flex gap-1 items-center capitalize font-semibold">
                      <MdOutlineLocalHospital />
                      {property?.landmark?.type}
                    </span>
                    <p className="font-bold">{property?.landmark?.name}</p>
                    <MapContainer
                      center={property?.coordinate?.split(",")}
                      zoom={13}
                      className="w-full h-[50vh] shadow-2xl rounded-xl z-10"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Circle
                        center={property?.coordinate?.split(",")}
                        radius={200}
                      />
                      <Marker position={property?.coordinate?.split(",")}>
                        <Popup>{property?.name}</Popup>
                      </Marker>
                      {property?.landmark?.coordinates && (
                        <Marker
                          position={property?.landmark?.coordinates?.split(",")}
                        >
                          <Popup>{property?.landmark?.name}</Popup>
                        </Marker>
                      )}
                    </MapContainer>
                  </div>
                </div>
              </div>
              <div
                className={`py-4 flex flex-col wrap justify-start md:gap-1 `}
              >
                <div className="space-y-4">
                  {property?.social?.tiktok[0] && (
                    <div>
                      <TikTokEmbed url={property?.social?.tiktok[0]} />
                    </div>
                  )}
                  {property?.social?.instagram[0] && (
                    <div>
                      <center>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: property?.social?.instagram[0],
                          }}
                        />
                      </center>
                    </div>
                  )}
                  {property?.social?.facebook[0] && (
                    <div>
                      <center>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: property?.social?.facebook[0],
                          }}
                        />
                      </center>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="grid grid-cols-4 gap-2 md:py-16 py-8">
                {Object.keys(property?.details || {}).map((key) => {
                  let details = { ...property?.details };
                  details.parking = [
                    featureIcons.bikes,
                    <span>{details.parking.bikes || 0}</span>,
                    featureIcons.cars,
                    <span>{details.parking.cars || 0}</span>,
                  ];
                  details.roadAccess = [
                    ...(details.roadAccess?.access
                      ? [
                          details?.roadAccess?.width,
                          " ",
                          details?.roadAccess?.unit,
                        ]
                      : ["No"]),
                  ];
                  return (
                    <PropertyDetailBox details={details} key={key} k={key} />
                  );
                })}
              </div> */}

              <div className="py-12 ">
                <p className="text-lg font-semibold">
                  This propery is viewed by {property?.visited} people.
                </p>
              </div>
              {/* <div>
                <MapComp coordinate={property?.coordinate.split(",")} />
              </div> */}
              {/* <div className="md:h-screen"></div> */}
            </div>

            {/* enquiry form */}
            {!adminActive && (
              <FormDetail
                register={register}
                errors={errors}
                Submit={handleSubmit(handleSubmitForm)}
                property={property}
                watch={watch}
                setValue={setValue}
                enquiry={enquiry}
              />
            )}
          </section>
        </section>
        <section>
          <SimilarProperties similarId={id} />
          <Featured />
          <Popular />
        </section>
        {!adminActive && <Footer />}
      </section>
    </>
  ) : (
    <>loading...</>
  );
}

function FormDetail({
  register,
  errors,
  Submit,
  property,
  watch,
  setValue,
  enquiry,
}) {
  const [title, setTitle] = useState("enquiry");
  const offerAmount = watch("offer", "");
  const handleOfferChange = (event) => {
    setValue("offer", event.target.value);
  };
  return (
    <div className="flex flex-col md:space-y-4 bg-white rounded-3xl p-4 shadow-big h-max md:sticky md:top-[12.5vh] mx-4 md:mx-4">
      <div className="flex justify-center items-center gap-x-4">
        <img
          src={enquiry?.data?.image || "/assets/TeamMember1.jpeg"}
          alt="enquiry"
          className="w-16 h-16 rounded-full shadow-md object-cover object-left-top"
        />
        <div className="spacee-y-2">
          <h2 className="font-semibold text-lg">
            {enquiry?.data?.name} || Pradip Karki
          </h2>
          <a
            href="tel:9818994768"
            className="flex justify-center items-center gap-x-2 font-semibold text-sm"
          >
            <IoCallOutline fontSize="20px" className="text-lime-900" />{" "}
            {enquiry?.data?.phone} || 9818994768
          </a>
        </div>
      </div>
      <Button.Group outline>
        <Button
          color={title === "enquiry" ? "primary" : "gray"}
          className="grow"
          onClick={() => setTitle("enquiry")}
        >
          Enquire Property
        </Button>
        <Button
          color={title === "offer" ? "primary" : "gray"}
          className="grow"
          onClick={() => setTitle("offer")}
        >
          Place Offer
        </Button>
      </Button.Group>
      {/* <h1 className="text-lg text-center font-bold text-lime-700">
        {title === "offer" ? "Offer" : "Enquiry"} Form
      </h1> */}
      <form className="flex flex-col min-w-sm space-y-2" onSubmit={Submit}>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email4" value="Email" />
          </div>
          <TextInput
            id="email4"
            type="email"
            icon={HiMail}
            placeholder="Enter your Email"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className="text-red-700">This field is required.</span>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name" value="Name" />
          </div>
          <TextInput
            id="name"
            type="text"
            icon={FaUser}
            placeholder="Enter Your Full Name"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span className="text-red-700">This field is required.</span>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="phone" value="Phone" />
          </div>
          <TextInput
            id="phone"
            type="phone"
            icon={MdLocalPhone}
            placeholder="Enter Your Phone Number"
            {...register("phone", { required: true })}
          />
          {errors.phone && (
            <span className="text-red-700">This field is required.</span>
          )}
        </div>
        {title === "enquiry" ? (
          <>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="message" value="Message" />
              </div>
              <Textarea
                id="message"
                placeholder="Leave a message..."
                {...register("message")}
                rows={3}
              />
            </div>
            <fieldset className="flex max-w-md flex-col gap-4 pb-2">
              <Label htmlFor="customerType" value="I am" />
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <Radio
                    id="buyer"
                    name="customerType"
                    value="buyer"
                    defaultChecked
                    {...register("type")}
                  />
                  <Label htmlFor="buyer">Buyer/Tenant</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio
                    id="agent"
                    name="customerType"
                    value="agent"
                    {...register("type")}
                  />
                  <Label htmlFor="agent">Agent</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio
                    id="Other"
                    name="customerType"
                    value="other"
                    {...register("type")}
                  />
                  <Label htmlFor="Other">Other</Label>
                </div>
              </div>
            </fieldset>{" "}
          </>
        ) : (
          <fieldset className="flex max-w-md flex-col gap-4">
            <Label>Choose Offer amount</Label>
            <div className="flex gap-x-2">
              <div className="flex items-center gap-2">
                <Radio
                  id="one"
                  name="offerAmount"
                  value={property?.price?.value - property?.price?.value / 100}
                  {...register("offer")}
                />
                <Label
                  htmlFor="one"
                  className="text-slate-600 text-sm font-normal"
                >
                  1% less
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="two"
                  name="offerAmount"
                  value={
                    property?.price?.value - (property?.price?.value * 2) / 100
                  }
                  {...register("offer")}
                />
                <Label
                  htmlFor="two"
                  className="text-slate-600 text-sm font-normal"
                >
                  2% less
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="five"
                  name="offerAmount"
                  value={
                    property?.price?.value - (property?.price?.value * 5) / 100
                  }
                  {...register("offer")}
                />
                <Label
                  htmlFor="five"
                  className="text-slate-600 text-sm font-normal"
                >
                  5% less
                </Label>
              </div>
            </div>
            <div>
              <TextInput
                id="offerAmount"
                type="text"
                // icon={MdLocalPhone}
                placeholder="Rs."
                value={offerAmount}
                onChange={handleOfferChange}
              />
              {errors.phone && (
                <span className="text-red-700">This field is required.</span>
              )}
            </div>
          </fieldset>
        )}
        <Button type="submit" color="primary">
          Submit {title === "offer" ? "Offer" : "Enquiry"}
        </Button>
      </form>
    </div>
  );
}

function PropertyDetailBox({ details, k }) {
  const [isHovered, setIsHovered] = useState(false);
  function handleMouseEnter() {
    setIsHovered(true);
  }
  function handleMouseLeave() {
    setIsHovered(false);
  }

  return (
    details?.[k] && (
      <div
        className=" bg-white shadow-lg border border-slate-200 rounded"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? (
          <div
            className={[
              "h-full flex flex-col p-3 items-center justify-center text-lime-600 pointer-events-none group",
              isHovered && "animate__animated animate__flipInX animate__fast",
            ].join(" ")}
          >
            <span className="text-xl font-semibold">
              {normalizeCamelCase(k)}
            </span>
            <span className="flex space-x-2 items-center font-semibold text-slate-500">
              {details[k]} {normalizeCamelCase(k)}
            </span>
          </div>
        ) : (
          <div
            key={k}
            className={[
              "flex p-3 flex-col items-center justify-center space-y-2",
            ].join(" ")}
          >
            <p className="text-lime-600">
              <span className="text-5xl inline">{featureIcons?.[k]}</span>
            </p>

            <span className="flex space-x-2 items-center font-semibold text-slate-500">
              {details[k]} {normalizeCamelCase(k)}
            </span>
          </div>
        )}
      </div>
    )
  );
}
