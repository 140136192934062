import { useQuery } from "@tanstack/react-query";
import { Footer } from "flowbite-react";
import { FooterLink } from "flowbite-react/lib/esm/components/Footer/FooterLink";
import React, { useEffect, useState } from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
// import { CiYoutube } from "react-icons/ci";
import { FaPhone, FaYoutube } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { PiTiktokLogoFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { getContent } from "../../apis";
export default function FooterSitemapLinks({ contentRef }) {
  const [_hideFooter, setHideFooter] = useState(true);
  const { data } = useQuery({
    queryKey: ["content"],
    queryFn: () => getContent(),
  });
  useEffect(() => {
    const target = contentRef?.current;
    function hideFooterFn() {
      if (target) {
        if (target?.scrollTop === document.documentElement.scrollHeight)
          setHideFooter(false);
        else setHideFooter(true);
      }
    }
    target?.addEventListener("scroll", hideFooterFn);
    return () => target?.removeEventListener("scroll", hideFooterFn);
  }, [contentRef]);
  return (
    <Footer
      id="footer"
      className={["shadow-big"].join(" ")}
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-8 py-8 md:grid-cols-4">
          <div className="flex flex-col gap-8">
            <div>
              <Footer.Title title="Company" />
              <Footer.LinkGroup col>
                <Footer.Link href="/about">About</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Contact Us" />
              <Footer.LinkGroup col>
                <Footer.LinkGroup className="flex items-center space-x-2">
                  <FaPhone />
                  {data?.data?.organization?.telephone ? (
                    data?.data?.organization?.telephone?.map((d) => (
                      <Footer.Link href={`tel:${d}`}>{d}</Footer.Link>
                    ))
                  ) : (
                    <>
                      <Footer.Link href="tel:9818994768">
                        +977 9818994768
                      </Footer.Link>
                      <Footer.Link href="tel:9851353969">
                        +977 9851353969
                      </Footer.Link>
                    </>
                  )}
                </Footer.LinkGroup>
                <Footer.LinkGroup className="flex items-center space-x-2">
                  <MdOutlineEmail />
                  <FooterLink
                    href={`mailto:${
                      data?.data?.organization?.email ||
                      "realestategp46@gmail.com"
                    }`}
                  >
                    {data?.data?.organization?.email ||
                      "realestategp46@gmail.com"}
                  </FooterLink>
                </Footer.LinkGroup>
                <address>
                  {data?.data?.organization?.location ||
                    "Imadol-4 Lalitpur, Nepal"}
                </address>
              </Footer.LinkGroup>
            </div>
          </div>
          <div>
            <Footer.Title title="social media" />
            <div className="flex gap-x-2">
              <Footer.LinkGroup col>
                <Footer.Icon
                  href={`${
                    data?.data?.organization?.instagram ||
                    "https://www.instagram.com/gprealestate_official?igsh=dGwydGhrb3Z0bmJk"
                  }`}
                  icon={BsInstagram}
                  className="text-red-500"
                />
                <Footer.Icon
                  href={`${
                    data?.data?.organization?.youtube ||
                    "https://youtube.com/@GPRealEstateServicesPvtLtd?si=xfwKzfZxjcrf2M79"
                  }`}
                  icon={FaYoutube}
                  className="text-red-500"
                />
                <Footer.Icon
                  href={`${
                    data?.data?.organization?.facebook ||
                    "https://www.facebook.com/gprealestateservices"
                  }`}
                  icon={BsFacebook}
                  className="text-blue-500"
                />
                <Footer.Icon
                  href={`${
                    data?.data?.organization?.tiktok ||
                    "https://www.tiktok.com/@gprealestate?_t=8iivMKGpMBU&_r=1"
                  }`}
                  icon={PiTiktokLogoFill}
                  className="text-black"
                />

                {/* <Footer.Icon href="#" icon={MdOutlineEmail} /> */}
              </Footer.LinkGroup>
              <Footer.LinkGroup col>
                <Footer.Link
                  href={`${
                    data?.data?.organization?.instagram ||
                    "https://www.instagram.com/gprealestate_official?igsh=dGwydGhrb3Z0bmJk"
                  }`}
                  target="_blank"
                >
                  Instagram
                </Footer.Link>
                <Footer.Link
                  href={`${
                    data?.data?.organization?.youtube ||
                    "https://youtube.com/@GPRealEstateServicesPvtLtd?si=xfwKzfZxjcrf2M79"
                  }`}
                  target="_blank"
                >
                  Youtube
                </Footer.Link>
                <Footer.Link
                  href={`${
                    data?.data?.organization?.facebook ||
                    "https://www.facebook.com/gprealestateservices"
                  }`}
                  target="_blank"
                >
                  Facebook
                </Footer.Link>
                <Footer.Link
                  href={`${
                    data?.data?.organization?.tiktok ||
                    "https://www.tiktok.com/@gprealestate?_t=8iivMKGpMBU&_r=1"
                  }`}
                  target="_blank"
                >
                  Tiktok
                </Footer.Link>

                {/* <Footer.Link href="#footer">Contact Us</Footer.Link> */}
              </Footer.LinkGroup>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <Footer.Title title="resources" />
              <Footer.LinkGroup col>
                <Footer.Link href="/listing">All Properties</Footer.Link>
                <Footer.Link href="/emi-calc">Emi Calculator</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="legal" />
              <Footer.LinkGroup col>
                <Footer.Link as={Link} to="/privacy-policy">
                  Privacy Policy
                </Footer.Link>
                <Footer.Link to="/terms-and-condition" as={Link}>
                  Terms & Conditions
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
          <div>
            <Footer.Title title="downloads" />
            <Footer.LinkGroup col>
              <Footer.Link href="#" className="pointer">
                <img
                  src="/assets/googleplayandroid.png"
                  alt="google Play(android)"
                  className="h-12 object-cover"
                />
              </Footer.Link>
              <Footer.Link href="#" className=" pointer">
                <img
                  src="/assets/appstore.png"
                  alt="IOS"
                  className="h-12 object-cover pl-3"
                />
              </Footer.Link>
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full  px-4 py-6 flex items-center md:justify-between justify-center md:flex-row flex-col">
          <Footer.Copyright
            by={
              <>
                <img src="/assets/gp_logo.png" className="w-8 inline" alt="" />
                <span> GP Real Estate Pvt. Ltd.</span>
              </>
            }
            href="#"
            year={2023}
          />
          <Footer.LinkGroup>
            <Footer.Link href="https://infinarygeeks.com.np">
              <span className="text-center font-bold text-xs">
                Powered by:{" "}
              </span>
              <img
                className="w-6 inline mr-2"
                src="/assets/logo-ig.png"
                alt=""
              />
              <span className="text-xs">Infinary Geeks Pvt. Ltd.</span>
            </Footer.Link>
          </Footer.LinkGroup>
        </div>
      </div>
    </Footer>
  );
}
