// import { Suspense } from 'react';
// use Gallant Font
import { Outlet, Route, Routes } from "react-router-dom";
import layouts from "./layouts";
import pages from "./pages";

// lightgallery css file
import "lightgallery.js/dist/css/lightgallery.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/PrivacyPolicy/TermsAndCondition";
// const pages = lazy(() => import('./pages'));

const router = (
  <Routes>
    <Route path="/loading" element={<pages.loading />} />
    <Route path="/" element={<layouts.Default />}>
      <Route index element={<pages.home />} />
      <Route path="/about" element={<pages.about />} />
      <Route path="/contact/:propertyCode" element={<pages.contact />} />
      <Route path="/emi-calc" element={<pages.tools />} />
    </Route>
    <Route path="/property/:id" element={<pages.property />} />

    <Route path="/listing" element={<layouts.listing />}>
      <Route index element={<pages.listing />} />
      <Route path=":type" element={<pages.listing />} />
    </Route>
    <Route path="/login" element={<pages.admin.login />} />
    <Route path="/admin" element={<layouts.admin />}>
      <Route index element={<pages.admin.Dashboard />} />
      <Route path="dashboard" element={<pages.admin.Dashboard />} />
      <Route path="message" element={<pages.admin.Message />}>
        <Route path=":customerId" element={<pages.admin.Message />} />
      </Route>

      <Route path="lead" element={<pages.admin.lead.lead />} />
      {/* <Route path="seo" element={<pages.admin.list.seo />} /> */}
      {/* <Route
                      path="create/seo"
                      element={<pages.admin.create.seo />}
                    /> */}

      <Route path="faq" element={<pages.admin.list.faq />} />
      <Route path="create/faq" element={<pages.admin.create.faq />} />
      <Route path="team" element={<pages.admin.list.team />} />
      <Route path="create/team" element={<pages.admin.create.team />} />
      <Route path="update/team/:teamId" element={<pages.admin.create.team />} />
      <Route path="lead/:leadId" element={<pages.admin.lead.leadDetail />} />
      <Route path="calender" element={<pages.admin.calender />} />

      <Route path="offer" element={<pages.admin.list.offer />} />
      <Route path="offer/create" element={<pages.admin.create.offer />} />

      <Route
        path="property/list/:pageNum"
        element={<pages.admin.list.property.property />}
      />
      <Route
        path="property/list/details/:id"
        element={<pages.admin.list.property.propertyDetail />}
      />
      <Route path="property/create" element={<pages.admin.create.property />} />
      <Route
        path="property/update/:id"
        element={<pages.admin.create.property />}
      />

      <Route path="settings" element={<Outlet />}>
        <Route path="list/contents" element={<pages.admin.list.contents />} />
        <Route path="list/customers" element={<pages.admin.list.customer />} />
        <Route path="list/partner/:id" element={<pages.admin.list.partner />} />
        <Route path="create/partner" element={<pages.admin.create.partner />} />
        <Route
          path="update/partner/:partnerId"
          element={<pages.admin.create.partner />}
        />
        <Route
          path="user/details/:id"
          element={<pages.admin.list.user.userDetail />}
        />
      </Route>
      <Route path="user/:pageNum" element={<pages.admin.list.user.user />} />
      <Route path="user/create" element={<pages.admin.create.user />} />
    </Route>
    <Route path="privacy-policy" element={<PrivacyPolicy />} />
    <Route path="terms-and-condition" element={<TermsAndConditions />} />
  </Routes>
);

export default router;
