// import { Suspense } from 'react';
// use Gallant Font
import { LightgalleryProvider } from "react-lightgallery";
import FilterProvider from "./context/ListingFilterContext";

// lightgallery css file
import { Flowbite } from "flowbite-react";
import "lightgallery.js/dist/css/lightgallery.css";
import { useEffect } from "react";
import AlertContextProvider from "./_contexts/AlertContext";
import AppContextProvider from "./context/AppContext";
import router from "./router";
// const pages = lazy(() => import('./pages'));

function App() {
  const theme = {
    button: {
      color: {
        primary: "bg-lime-500 hover:bg-lime-600",
      },
    },
  };

  useEffect(() => {
    if (localStorage.getItem("gp_user") === "undefined") {
      localStorage.removeItem("gp_user");
    }
  }, []);
  return (
    <>
      <Flowbite theme={{ theme }}>
        <AppContextProvider>
          <AlertContextProvider>
            <FilterProvider>
              <LightgalleryProvider>
                {/* <Suspense fallback={<pages.loading />}> */}
                {router}
                {/* </Suspense> */}
              </LightgalleryProvider>
            </FilterProvider>
          </AlertContextProvider>
        </AppContextProvider>
      </Flowbite>
    </>
  );
}

export default App;
